





















































































import {Vue, Component, Prop} from 'vue-property-decorator';
import PortalSelect from "@/components/common/PortalSelect.vue";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import { IEmployment } from "@/dto/payroll/IEmployment";
import { CreateVacationPayload, CreateVCRequestPayload } from "@/dto/payroll/VacationDTO";
import Application from "@/state/Application";
import VacationService from "@/services/payroll/VacationService";
import {ifValid, processError} from "@/utils/ComponentUtils";
import PortalTextarea from "@/components/common/PortalTextarea.vue";
import SelectOption from "@/components/common/SelectOption";
import moment from "moment";
import { WorkspaceType } from "@/dto/auth/Workspace";
import { AxiosResponse } from "axios";

@Component({
	components: {
    PortalTextarea,
    PortalCheckbox,
    PortalDate,
    PortalSelect
  },
  computed: {
    WorkspaceType() {
      return WorkspaceType;
    }
  }
})
export default class VacationRequestCreationModal extends Vue {

  @Prop({ default: [] })
  private employments!: Array<IEmployment>;

  @Prop()
  private callback!: () => Promise<void>;

  @Prop({ default: null })
  private periodFromCalendar!: { startDate: Date, endDate: Date };

	private req = this.$wss.getCurrent.type === WorkspaceType.PERSON ?
    new CreateVCRequestPayload({ startTime: "10:00", endTime: "18:00" }) :
    new CreateVacationPayload({ startTime: "10:00", endTime: "18:00" })

  mounted(): void {
    if (this.employments.length === 1) {
      this.req.employmentId = this.employments[0].id;
    }
    if (this.periodFromCalendar) {
      this.req.startDate = this.periodFromCalendar.startDate;
      this.req.endDate = this.periodFromCalendar.endDate;
    }
  }

	private create() {
    ifValid(this, () => {
    let handler: Promise<AxiosResponse<void>>;
    if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
      handler = VacationService.requestVacation(this.req as CreateVCRequestPayload);
    } else {
      handler = VacationService.createVacationRecord(this.req);
    }
			Application.startLoading();
      handler.then(
				() => {
					this.$modal.hideAll();
					Application.stopLoading();
          this.callback();
				},
				err => processError(err, this)
			)
		})
	}

  private get buildOptions(): Array<SelectOption> {
    if (this.$wss.getCurrent.type === WorkspaceType.PERSON) {
      return this.employments.map(item => SelectOption.builder().title(item.employer.name).value(item.id).build());
    }
    return this.employments.map(item => SelectOption.builder().title(item.details.name).value(item.id).build());
  }

  private get duration() {
    return moment.duration(moment(this.req.endDate).add(1, 'days').diff(moment(this.req.startDate))).as('days');
  }

}
